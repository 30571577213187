@font-face {
  font-family: 'OpenSans';
  src:  url('/assets/fonts/OpenSans-Light-webfont.woff') format('woff');
  font-weight: $font-weight-light;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src:  url('/assets/fonts/OpenSans-Regular-webfont.woff') format('woff');
  font-weight: $font-weight-normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src:  url('/assets/fonts/OpenSans-Bold-webfont.woff') format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}
