$brand_red:     #ff3c00;
$brand_yellow:  #ebff4b;
$brand_blue:    #0064ca;
$brand_black:   #000000;
$brand_darkgrey:#303030;
$brand_grey:    #c0c0c0;
$brand_concrete:#eaeaea;
$brand_white:   #ffffff;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;


// http://www.modularscale.com/?1&em&1.5

$font-size-50: 5.2em; // 🔒 // constellationovl.h2 newsletter>h2
$font-size-30: 3em; // newsdet>h1
$font-size-26: 2.6em; // text>h1
$font-size-23: 2.4em;  // 🔒// h3, menu>a teammember>h3
$font-size-20: 2.00em;  // 🔒// h4, text teammember>h4
$font-size-17: 1.75em; // h4, footerlinks>a #intermezzo>text, news>artcnt newsletter>tx
$font-size-1a7: 1.5em; // text>h3 text>h4 article>h2 newsdet>datesource newsdet>mntxt
$font-size-19: 1.20em; // slider[MEDIA]>sliderovl
$font-size-16: 1.00em; // form[email] newsletter>button
$font-size-08: 0.8em; // newsoverview>arthd

$line-height-none: 1; // newsletter>h2 newsletter>button
$line-height-narrow: 1.2; // FS-26, article>h2 newsdet>datesource newsdet>mntxt newsletter>tx teammember>h4
$line-height-tight: 1.35;  // 🔒 // p, footerlinks>a, news>artcnt
$line-height-normal: 1.5; // form[email] newsoverview>arthd
$line-height-loose: 2; // menu>a

// http://www.modularscale.com/?1&em&1.333
$margin-none: 0;
$margin-1: 0.317em;
$margin-3: 1.000em;
$margin-5: 1.777em;
$margin-7: 3.157em;

// http://www.modularscale.com/?1&em&1.333
$padding-none: 0;
$padding-1: 0.317em;
$padding-2: 0.563em;
$padding-3: 1.000em;
$padding-4: 1.333em;
$padding-5: 1.777em;
$padding-6: 2.369em;
$padding-7: 3.157em;
$padding-8: 4.209em;
$padding-9: 5.610em;
$padding-10: 7.478em;
$padding-11: 9.969em;
$padding-12: 13.288em;
