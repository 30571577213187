body.home,
.t-home {
	#logowrap {
		display: block;
		position: absolute;
		top: -4.5rem;
	}
	.off-canvas-content {
		padding-top: 0;
	}
	footer {
		padding-top: $padding-6;
	}

  .intermezzo,
  .jobs {
    color: $brand_darkgrey;
    padding-top: $padding-11;
    padding-bottom: $padding-10;
    background-color: $brand_concrete;

    .text {
      padding-top: 0;

      h1 {
        font-weight: $font-weight-normal;
        white-space: nowrap;
      }

      p {
        margin-bottom: 0;
        margin-top: 0;
      }
      .linkborder {
        font-size: $font-size-20;
        font-weight: $font-weight-normal;
        line-height: $line-height-narrow;
      }
    }
  }

  .constellation {
    position: relative;
    margin-bottom: -.2em;
    background-color: $brand_black;
    img {
      width: 140%;
      max-width: none;
      margin-left: -20%;
    }

    .constellationovl {
      position: absolute;
      top: $padding-9;
      left: 0;
      color: $brand_white;
      h2 {
        // font-size: $font-size-50;
        // line-height: $line-height-none;
        // font-weight: $font-weight-bold;
        // margin-bottom: $margin-1;
        // padding: 0;
        font-size: $font-size-50;
        line-height: $line-height-narrow;
        font-weight: $font-weight-normal;
        color: $brand_red;
        padding-bottom: $padding-1;
      }
      .tx {
        // font-size: $font-size-17;
        // line-height: $line-height-tight;
        // a {
        // 	color: inherit;
        // }
        padding-right: 70px;
      }
      .linkborder {
        font-size: $font-size-20;
        font-weight: $font-weight-normal;
        line-height: $line-height-narrow;
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  body.home {

    .intermezzo {
      padding-top: $padding-10;
    }

    .jobs {
      padding-top: $padding-8;
    }

    .constellation {
      // height: 75vw;
      // margin-bottom: 0;
      // top: 0;
      padding-top: $padding-6;
      padding-bottom: $padding-10;
      min-height: 480px;

      // .constellationovl {
      //   position: relative;
      //   top: 0;
      //   // h2 {
      //   //   font-size: $font-size-30;
      //   // }
      //   // .tx {
      //   //   font-size: $font-size-17;
      //   // }
      // }
    }
  }
}

@media screen and (min-width: 40em) {
	body.home .constellation {
		img {
			width: 100%;
			max-width: 100%;
			margin-left: 0;
		}
	}
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  body.home {
		#logowrap {
			top: -5.5rem;
		}
	}
}

@media screen and (min-width: 64em) {
	body.home {
		#logowrap {
			top: -7.5rem;
		}
	}
}
