/////////////////////////////////////////
// modules
@import "variables";
@import "fonts";
@import "base";
@import "footer";
@import "nav";
@import "text";
@import "cookie";
@import "logo";
@import "slider";
@import "newsletter";
@import "form";

/////////////////////////////////////////
// templates
@import "home";
@import "constellation";
@import "jobs";
@import "jobsoverview";
@import "jobdetail";
@import "newsoverview";
@import "newsdetail";
@import "news";
@import "about";

/////////////////////////////////////////
// helpers

// #ovl {
// 	display: block;
// 	position: fixed;
// 	width: 100%;
// 	height: 100%;
// 	background-color: $brand_white;
// 	z-index: 999999;
// }

.frow {
	padding-left: $padding-9;
	padding-right: $padding-9;
}

.hdimg {
	width: 100%;
}

.jobmore {
	display: inline-block !important;
}

.linkborder {
	position: relative;
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: -.5rem;
		width: 50%;
		left: 0;
		border-bottom: .2rem solid;
	}
}

@media screen and (max-width: 39.9375em) {
	.frow {
		padding-left: 0;
		padding-right: 0;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	.frow {
		padding-left: $padding-6;
		padding-right: $padding-6;
  }
}
