input {
	&::-webkit-input-placeholder {
		color: $brand_black;
	}
	&::-moz-placeholder {
		color: $brand_black;
	}
	&:-ms-input-placeholder {
		color: $brand_black;
	}
	&::placeholder {
		color: $brand_black;
	}
}
textarea {
	&::-webkit-input-placeholder {
		color: $brand_black;
	}
	&::-moz-placeholder {
		color: $brand_black;
	}
	&:-ms-input-placeholder {
		color: $brand_black;
	}
	&::placeholder {
		color: $brand_black;
	}
}
[type='email'] {
  font-size: $font-size-16;
  line-height: $line-height-normal;

	height: auto;
	padding: $padding-2;
	border: 2px solid $brand_black;
	margin: 0;
	color: $brand_black;
	background-color: $brand_yellow;
	box-shadow: none;
	border-radius: 0;
	transition: background-color .25s ease-out, color .25s ease-out;
	&:focus {
		border: 2px solid $brand_black;
		background-color: $brand_black;
		color: $brand_yellow;
		outline: none;
		box-shadow: none;
		transition: background-color .25s ease-out, color .25s ease-out;
	}
}
[type='submit'].button {
	&:focus {
		background-color: $brand_black;
		color: $brand_yellow;
	}
}
