#logoaniwrap {
	width: 100%;
	height: 100vh;
	background: $brand_yellow;
	position: relative;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
#logoanicnt {
	width: 40%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 30%;
	svg {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
	}
}
#hlogo {
	width: 11.875rem;
	height: 2.375rem;
	margin-top: 2.725rem;
	transition: all .5s ease;
	rect {
		fill: $brand_red;
	}
	path {
		fill: $brand_black;
		opacity: 1;
		transition: fill .5s ease;
	}
}
#logoclaim {
	font-weight: $font-weight-bold;
	font-size: $font-size-17;
	text-align: right;
	line-height: 1.025em;
	position: absolute;
	top: 1.45em;
	right: 2.8571em;
}

@media screen and (max-width: 39.9375em) {
  #logoclaim {
		font-size: 4em;
		top: .5em;
		right: 1.25em;
	}
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
	#hlogo {
		margin-top: 1.861875rem;
		width: 15rem;
		height: 2.93625rem;
  }
}

@media screen and (min-width: 64em) {
	#hlogo {
		margin-top: 2.675875rem;
		margin-top: 1.9rem;
		width: 14.6rem;
		height: 4.30825rem;
	}
}
