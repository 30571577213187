body.news,
.t-news {

  .newsoverview {
    padding-top: $padding-7;
    section {
      padding-top: $padding-4;
      padding-bottom: $padding-9;
    }
    .text {
      .artread {
        display: block;
        font-size: $font-size-17;
        font-weight: $font-weight-bold;
        color: inherit;
        margin-top: $margin-3;
      }
      a {
        color: inherit;
      }
      // h1 {
      //   p {
      //     // margin-bottom: $margin-1; // TODO: invalid
      //   }
      // }
      p {
        margin-bottom: $margin-1;
      }
      .arthd {
        font-size: $font-size-17;
        line-height: $line-height-normal;
      }
      h2 {
        font-weight: $font-weight-bold;
        color: $brand_black;
        margin-bottom: $margin-1;
      }
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 39.9375em) {
  body.news,
  .t-news {
    .newsoverview {
      .hdimg {
        padding-bottom: $padding-4;
      }
    }
  }
}
