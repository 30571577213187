
html, body {
	height: 100%;
	width: 100%;
}
body {
	background: $brand_white;
	position: relative;
	margin: 0;
  padding: 0;
}
* {
  font-family: OpenSans, sans-serif;
  font-weight: $font-weight-light;
}
p, button, li b,  li li {
  font-size: $font-size-20;
  line-height: $line-height-narrow;
}
p {
  max-width: 83ch;
}
h1, h2, h3, h4, h5, h6 {
  font-family: OpenSans, sans-serif;
  padding: 0;
  margin: 0;
}
h1 {
  font-size: $font-size-50;
  line-height: $line-height-narrow;
  font-weight: $font-weight-bold;
  max-width: 32ch;
}
h2 {
  font-size: $font-size-23;
  line-height: $line-height-normal;
  font-weight: $font-weight-bold;
}
h3 {
  font-size: $font-size-20;
  line-height: $line-height-normal;
  font-weight: $font-weight-normal;
}
h4, h5, h6 {
  font-size: $font-size-16;
  line-height: $line-height-normal;
}
b, strong { // TODO: remove overused stront tags
	font-weight: $font-weight-bold;
	font-style: normal;
}
ol, ul, ul ul {
  margin-left: $margin-5;
  margin-bottom: $margin-5;
}
footer {
  font-size: $font-size-16;
  line-height: $line-height-normal;
}
a {
	color: $brand_blue;
}
