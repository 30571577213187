
footer {
	position: relative;
	width: 100%;
	background: $brand_white;
	padding-top: $padding-4;
	padding-bottom: $padding-6;
	.footerlinks {
		a {
			display: inline-block;
			margin-right: $margin-3;
      margin-top: $margin-3;
      color: inherit;
      font-size: $font-size-17;
      line-height: $line-height-tight;
		}
	}
}

@media screen and (max-width: 39.9375em) {
	footer {
    padding-top: $padding-6;
    padding-bottom: $padding-6;

		.footerlinks {
			span {
				display: block;
			}
			a {
				margin-top: 0;
				margin-bottom: $margin-1;
			}
		}
  }
}
