.text {
  padding-top: $padding-7;

  h1, h2 {
    color: $brand_red;
    padding-bottom: $padding-1;
    font-weight: $font-weight-light;
  }

  p {
    padding-bottom: $padding-1;
  }
}
