.sliderwrap {
	position: relative;
  margin-top: 10rem;

  .slider {
    height: 75vw;

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 140%;
      max-width: none;
      margin-left: -20%;
      img {
        width: 100%;
      }
    }
  }

  .sliderovl {
    position: absolute;
    top: $padding-9;
    left: 0;

    .columns {
      font-size: $font-size-26;
      line-height: $line-height-none;
      color: $brand_white;
    }
    .columns strong {
      font-weight: $font-weight-normal;
    }
    .columns p {
      line-height: $line-height-none;
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .sliderwrap .sliderovl {
		top: $padding-6;
		.columns {
			font-size: 1.1em; // TODO:
			strong {
				font-size: 1.2em; // TODO:
      }
      p {
        line-height: $line-height-narrow;
      }
		}
	}
}

@media screen and (min-width: 40em) {
  .sliderwrap .slider {
		height: 54.5vw;
		.slide {
      width: 100%;
			margin-left: 0;
		}
	}
}
