.newsdet {
	position: relative;
	.datesource {
    font-size: $font-size-1a7;
    line-height: $line-height-narrow;
		padding-bottom: $padding-4;
		padding-top: $padding-7;
	}
	h1 {
    font-size: $font-size-30;
    // TODO: line-height
		font-weight: $font-weight-bold;
		padding: 0 0 $padding-2 0;
		// p {
    //   font-size: $font-size-17;
		// 	line-height: $line-height-tight;
    //   margin: 0;
    //   color: magenta; // TODO: this is invalid
		// }
	}
	.mntxt {
		font-size: $font-size-16;
		line-height: $line-height-narrow;
		p {
			margin-bottom: $margin-3;
		}
	}
}
