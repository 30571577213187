nav {
	position: absolute;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 9.66rem;
	transition: transform .5s ease;
}
$navTransX: 20rem;
nav.cmisopen {
	transform: translateX(-$navTransX);
	position: fixed;
	#burger {
		rect {
			fill: $brand_red;
		}
		transform: translateX(10.625rem) translateY(-1.0893rem);
		#brg1 {
			transform: translateX(.5rem) translateY(-.9rem) rotate( 225deg );
			-moz-transform: translateX(.5rem) translateY(-.9rem) rotate( 225deg );
		}
		#brg3 {
			transform: translateX(.5rem) translateY(.9rem) rotate( -225deg );
			-moz-transform: translateX(.5rem) translateY(.9rem) rotate( -225deg );
		}
		#brg2 {
			opacity: 0;
		}
	}
}
#burger {
	float: right;
	margin-top: 3.5707rem;
	width: 2.5186rem;
	height: 2.5186rem;
	cursor: pointer;
	transition: transform .5s ease;
	#brg1 {
		transition: transform .75s ease;
		transform-origin: 50% 50%;
	}
	#brg2 {
		transition: transform .75s ease;
		transform-origin: 50% 50%;
	}
	#brg3 {
		transition: transform .75s ease;
		transform-origin: 50% 50%;
	}
	rect {
		fill: $brand_black;
		opacity: 1;
		transition: fill .5s ease;
	}
}
.off-canvas-wrapper {
	min-height: 100%;
}
.off-canvas-wrapper-inner {
	background: $brand_white;
	min-height: 100%;
}
.off-canvas {
	position: fixed;
	background: none;
	z-index: 0;
}
.off-canvas.position-right {
	right: -$navTransX;
	width: 20rem;
	transition: all .7s ease;
}
.off-canvas.position-right.cmisopen {
	right: 0;
}
.off-canvas-content {
	min-height: 100vh;
	box-shadow: none;
	padding-top: 9.6rem;
}
.off-canvas-content.cmisopen {
	transform: translateX(-$navTransX);
}

.menu {
	padding-top: 9rem;
	text-align: right;
	.row {
		padding-right: $padding-6;
	}
	a {
    display: block;
    // HACK: was rem
		font-size: $font-size-23;
    line-height: $line-height-loose;

		color: $brand_darkgrey;
		&:hover {
			color: $brand_red;
		}
	}
	a.active {
    color: $brand_red;
    font-weight: $font-weight-normal;
	}
}

@media screen and (max-width: 39.9375em) {
	.off-canvas-content {
		padding-top: 9em;
	}
	nav {
		height: 5.625rem;
	}
	#burger {
		margin-top: 2.4em;
	}
	nav.cmisopen {
		#burger {
			transform: translateX(5rem) translateY(0);
		}
	}
	.off-canvas.position-right {
		width: 100vw;
		right: -100vw;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .off-canvas-content {
		padding-top: 6.6rem;
	}
	.off-canvas.position-right {
		width: 14.3rem;
	}
	.off-canvas-content.cmisopen {
		transform: translateX(-16rem);
	}
	nav.cmisopen {
		#burger {
			transform: translateX(10.625rem) translateY(-1.0893rem);
			transform: translateX(7.125rem) translateY(-.5rem);
		}
		transform: translateX(-16rem);
	}
	.menu {
		padding-top: 6.07rem;
		.row {
			padding-right: 2rem;
		}
		a {
			font-size: 1.5rem;
			line-height: 2.68rem;
		}
	}
	nav {
		height: 6.66rem;
	}
	#burger {
		margin-top: 2.33rem;
		width: 2rem;
		height: 2rem;
	}
}
