.jobdetail {
	padding-top: 0;
	section {
		padding-top: 0;
	}
	.text {
		p {
			margin-bottom: $margin-3;
    }
    ul:last-of-type {
      margin-bottom: $padding-10;
    }
    li {
      font-size: $font-size-20;
      max-width: 83ch;
      margin-bottom: $margin-1;
      margin-left: 2px;
      padding-left: $padding-1;
    }
    .tags {
      font-weight: $font-weight-bold;
      font-size: $font-size-20;
      line-height: $line-height-normal;
      font-weight: $font-weight-bold;
    }
    h3 {
      border-top: 1px solid $brand_grey;
      padding-top: $padding-3;
      font-weight: $font-weight-bold;
      margin-bottom: $margin-3;
    }
    p + h3, ul + h3 {
      margin-top: $padding-7;
    }
    ul + p>strong:first-child {
      display: inline-block;
      padding-top: $padding-3;
    }
	}
}
