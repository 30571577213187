.newsletter {
	position: relative;
	background: $brand_yellow;
	padding-top: $padding-9;
	padding-bottom: $padding-9;
	h2 {
		font-size: $font-size-50;
		line-height: $line-height-none;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		margin: 0 0 $margin-3 0;
		padding: 0;
	}
	.tx {
		font-size: $font-size-17;
		line-height: $line-height-tight;
	}
	.button {
		height: auto;
		transition: background-color .25s ease-out,color .25s ease-out;
		border: 2px solid $brand_black;
		border-radius: 0;
		padding: $padding-2 $padding-4;
		margin: 0;
		background-color: $brand_yellow;
    color: $brand_black;

    font-size: $font-size-16;
    line-height: $line-height-none;
		font-weight: $font-weight-bold;
		&:hover {
			background-color: $brand_black;
			color: $brand_yellow;
		}
	}
}

@media screen and (max-width: 39.9375em) {
	.newsletter {
		padding-top: $padding-6;
		padding-bottom: $padding-6;
		h2 {
			font-size: 3em;
			margin: 0 0 $margin-3 0;
		}
		.button {
			margin-top: $margin-3;
			width: 100%;
			background-color: $brand_black;
			color: $brand_yellow;
		}
  }
}
