body.constellation,
.t-constellation {
	.off-canvas-content {
		color: $brand_darkgrey;
		footer {
			color: $brand_black;
		}
		.row {
			background: $brand_white;
		}
		.conpad {
			padding-bottom: $padding-9;
		}
	}
	nav {
		background: $brand_white;
	}
	.row.hdpicrow {
		background: none;
	}
	.row.hdpicbgrow {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $brand_blue;
		background-size: 100% auto;
	}
	.row.tx1 {
		padding-bottom: $padding-6;
		padding-top: $padding-6;
		padding-bottom: $padding-9;
		background-color: $brand_concrete;
  }
  .posrel {
    position: relative;
  }
  .constellationicons {
    background-color: $brand_white;
    .tx2 {
      padding-top: $padding-9;
    }
    .text {
      figure {
        margin: 0 0 $margin-7 0;
        width: 16em;
        img {
          width: 16em;
          height: 16em;
        }
      }
    }
    .text.ico {
      padding-top: 0;
    }
  }
  .constellationvideos {
    .tx3 {
      padding-top: $padding-7;
      padding-bottom: $padding-9;
      background-color: $brand_concrete !important;
    }
    .tx4 {
      padding-bottom: $padding-4;
    }
  }
}


@media screen and (max-width: 39.9375em) {
  body.constellation, .t-constellation {
    .constellationicons {
      figure {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}
