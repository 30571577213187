.jobsoverview {
	// padding-top: $padding-7;
	.jobs {
		padding-top: $padding-4;
		padding-bottom: $padding-4;
  }
  .job {
    display: block;
    height: 18em;
    padding: $padding-5 $padding-6;
    margin-bottom: $margin-5;
    color: $brand_black;
    background-color: $brand_concrete;
    &:hover {
      color: $brand_white;
      background-color: $brand_red;
    }
    position: relative;
    .tags {
      position: absolute;
      bottom: $padding-6;
      left: $padding-6;
      font-weight: $font-weight-bold;
    }
  }
	.text {
		a {
			display: block;
			font-weight: $font-weight-bold;
			color: inherit;
			margin-top: $margin-3;
		}
		p {
			margin-bottom: $margin-3;
    }
    ul.filters {
      list-style: none;
      margin-left: 0;

      li {
        display: inline-block;
        a {
          display: block;
          //border: 2px solid $brand_concrete;
          background-color: $brand_concrete;
          color: $brand_black;
          margin-right: $margin-1;

          font-size: $font-size-20;
          font-weight: $font-weight-light;
          line-height: 1em;

          &:hover {
            background-color: $brand_red;
            color: $brand_white;
            &.became-inactive { // js
              background-color: $brand_concrete;
              color: $brand_black;
            }
          }
          &.is-active {
            background-color: $brand_red;
            color: $brand_white;
            &:hover {
              background-color: $brand_concrete;
              color: $brand_black;
            }
          }
          padding: $padding-3 $padding-5;
        }
      }
    }
	}
}
