#cookie_banner-wrapper {
	z-index: 9001;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	border-top: 1px solid $brand_black;
	.cookie_container {
		background-color: $brand_darkgrey;
		color: $brand_white;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.cookie_message {
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		display: inline-block;
		font-size: $font-size-19;
		line-height: $line-height-tight;
    max-width: 92ch;
    margin-bottom: 0;
    padding: $padding-5;

		a {
			color: $brand_white;
			text-decoration: underline;
			&:active {
				color: $brand_white;
				text-decoration: underline;
				&:not(.silent) {
					&:not(.button) {
						display: inline-block;
						text-decoration: underline;
					}
				}
			}
			&:focus {
				color: $brand_white;
				text-decoration: underline;
			}
			&:hover {
				color: $brand_white;
				text-decoration: underline;
				&:not(.silent) {
					&:not(.button) {
						display: inline-block;
						text-decoration: underline;
					}
				}
			}
			&:visited {
				color: $brand_white;
				text-decoration: underline;
			}
		}
	}
	.cookie_btn {
		cursor: pointer;
    font-size: $font-size-20;
    line-height: $line-height-tight;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		border-color: transparent;
		border-width: 2px;
		border-radius: 0px;
		background-color: transparent;
		padding: $padding-3 $padding-6;
		// margin-left: $margin-5;
		// margin-right: $margin-5;
		// margin-top: $margin-5;
		color: transparent;
		background-position: center center;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAU0lEQVR4AWP4//+/HhDvBWIRBgIApAaqVo8BzICAiyAJApouQtXuhQvg0YxVDT4J/HL4FODThFczLk0ENaNrokgjRU6lKHDIjg6yEwDZSY7sRA4AEwCdBJshNJEAAAAASUVORK5CYII=');
		background-repeat: no-repeat;
		background-size: 12px 12px;
		&:focus {
			background-color: transparent;
			border-color: transparent;
			color: transparent;
		}
		&:hover {
			background-color: transparent;
			border-color: transparent;
			color: transparent;
		}
	}
}
@media print {
	#cookie_banner-wrapper {
		display: none;
	}
}
@media (min-width:768px) {
	#cookie_banner-wrapper {
		.cookie_container {
			padding: 0;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		.cookie_message {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			a {
				display: inline-block;
			}
		}
		.cookie_btn {
			margin-top: 0;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin-right: 0;
			background-color: transparent;
			border-color: transparent;
			&:hover {
				background-color: $brand_darkgrey;
				border-color: $brand_darkgrey;
			}
		}
	}
}
