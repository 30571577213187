.news {
	position: relative;
	background-color: $brand_concrete;
	overflow-x: hidden;
	a {
		&:nth-child(2) {
			article {
				background-color: $brand_darkgrey;
			}
		}
		&:nth-child(4) {
			article {
				background-color: $brand_darkgrey;
			}
		}
	}
	article {
		padding-top: $padding-12;
		padding-bottom: $padding-10;
		.artcnt {
			font-size: $font-size-17;
			line-height: $line-height-tight;
			color: $brand_darkgrey;
		}
		.arthd {
			margin-bottom: $margin-3;
		}
		h2 {
			font-weight: $font-weight-bold;
			font-size: $font-size-1a7;
			line-height: $line-height-narrow;
			margin: 0 0 $margin-3 0;
      padding: 0;
		}
		.artread {
			display: inline-block;
			margin-top: $margin-3;
			font-weight: $font-weight-bold;
			color: inherit;
		}
	}
	.newspts {
		position: absolute;
		bottom: $padding-6;
		right: 0;
		.newspt {
			width: $padding-5;
			height: $padding-5;
			float: left;
			margin-left: $margin-3;
			svg {
				width: 100%;
				height: 100%;
				circle {
					fill: $brand_grey;
				}
			}
			&:hover {
				cursor: pointer;
				svg {
					circle {
						fill: $brand_yellow;
					}
				}
			}
		}
		.newspt.active {
			svg {
				circle {
					fill: $brand_yellow;
				}
			}
		}
	}
	.newshdr {
		position: absolute;
		top: $padding-9;
		left: 0;
		.columns {
			font-weight: $font-weight-bold;
			font-size: $font-size-50;
			color: $brand_white;
		}
	}
}
.newscnt {
	transition: transform .5s ease;
}
.newscnt.news-1 {
	transform: translateX(0);
}
.newscnt.news-2 {
	transform: translateX(-25%);
}
.newscnt.news-3 {
	transform: translateX(-50%);
}
.newscnt.news-4 {
	transform: translateX(-50%);
}

@media screen and (max-width: 39.9375em) {
	.news {
		article {
			padding-top: $padding-6;
			padding-bottom: $padding-6;
			&:nth-child(1) {
				padding-top: $padding-9;
			}
			h2 {
				font-size: 1.3em;
			}
		}
		.newshdr {
			top: 1.3125em;
			.columns {
				font-size: 3em;
			}
		}
  }
}
