// #about {
// 	padding-top: $padding-7;
// 	section {
// 		padding-top: $padding-4;
// 		padding-bottom: $padding-6;
// 	}
// 	.text {
// 		h1 {
// 			p {
// 				margin-bottom: $margin-1;
// 			}
// 		}
// 		p {
// 			margin-bottom: $margin-3;
// 		}
// 	}
// }

body.about,
.t-about {
  .teammember {
    .members {
      display: flex;
      flex-wrap: wrap;
    }
    .member figure {
      padding: 0;
      margin: 0;

      img {
        padding-top: $padding-4;
        padding-bottom: $padding-6;
      }

      h2 {
        font-weight: $font-weight-normal;
      }

      h3 {
        font-weight: $font-weight-light;
      }

      h2, h3 {
        line-height: $line-height-tight;
      }

      h3 {
        padding-bottom: $padding-1;
      }
    }

    // h3 {
    //   padding-top: $padding-2;
    //   font-size: $font-size-23;
    //   // TODO: line-height
    // }
    // h4 {
    //   font-weight: $font-weight-light;
    //   font-style: normal;
    //   padding-top: 0;

    //   font-size: $font-size-20;
    //   line-height: $line-height-narrow;
    // }
    .text {
      padding-top: $padding-2;
    }
  }
}

@media screen and (max-width: 39.9375em) {
	body.about .teammember {
		.member {
			padding-bottom: $padding-7;
		}
  }
}
